body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  height: 40px;
}

.css-1fq90p7-MuiFormControl-root-MuiTextField-root {
  margin-top: 0px !important;
}

.css-ypiqx9-MuiDialogContent-root {
  padding: 0px 24px !important;
}

.css-16ua3fx-MuiDialogActions-root {
  margin-top: 0px !important;
}

.css-ajqju7-MuiTypography-root-MuiDialogTitle-root {
  padding-bottom: 0px !important;
}

.css-v1q3cf-MuiButtonBase-root-MuiButton-root {
  padding: 8px 19px !important;
}

.css-mueg4c-MuiButtonBase-root-MuiButton-root {
  padding: 8px 19px !important;
}

.css-1wx7zqd-MuiButtonBase-root-MuiButton-root {
  padding: 12px 13px !important;
}

.table-header-font {
  font-size: 15px !important;
  font-weight: 600 !important;
}
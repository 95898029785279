.App-logo {
  width: 100px;
  height: 100px;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 1s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.PE_sidebar::-webkit-scrollbar {
  display: none;
  scroll-behavior: smooth;
}

.PE_sidebar .MuiListItemIcon-root {
  min-width: 40px !important;
}
.PE_sidebar .MuiListItem-root {
  padding-top: 4px;
  padding-bottom: 4px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* add for solve conflict of mui and bootstrap in textfiled */
legend {
  display: block;
  width: auto;
  float: unset;
}




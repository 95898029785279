.slot-date {
    background-color: #0957DD;
    padding: 4px 7px 4px 7px;
    border-radius: 5px;
    color: white;
}

.slot-line-peding {
    padding: 0px 0px 7px 0px;
}

.slot-bg-color {
    background-color: #8adb97;
    padding: 4px;
    border-radius: 5px;
}

.patient-relation-title {
    background-color: #0957DD;
    padding: 4px 8px 4px 8px;
    border-radius: 10px;
    color: white;
    width: 100%;
    font-size: 14px;
}

.booking-id {
    background-color: #008d2b;
    padding: 6px 10px 6px 10px;
    color: #ffffff;
    border-radius: 5px;
}

.back-btuoon-details {
    font-size: 35px !important;
    background-color: #0957DD;
    padding: 5px 0px 6px 10px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
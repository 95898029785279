.back-btuoon-details-equip-order {
    font-size: 15px !important;
    background-color: #0957DD;
    padding: 8px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.equipment-list-order-number {
    color: #0957DD !important;
    font-weight: 600 !important;
}

.hover-effect:hover {
    color: #0957DD !important;
}

/* .table-header-font {
    font-size: 15px !important;
    font-weight: 600 !important;
} */
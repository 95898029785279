.pd_slider_and_details_grid {
    border-bottom: 1px solid rgba(15, 15, 15, 0.15);
    padding-bottom: 10px;
}


.pd_img_slider {
    border-radius: 12px !important;
    height: 400px;
    width: 90%;
    background-attachment: fixed;
}

.pd_product_img {
    border-radius: 10px;
    width: 144px;
    height: 137px;
}

.pd_details_desc_grid {
    /* border-bottom: 1px solid rgba(15, 15, 15, 0.15); */
    padding-bottom: 24px;
}

.pd_h1 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #0F0F0F;
    margin: 0;
}

.pd_p {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 34px;
    color: #0F0F0F;
    margin: 0;

}

.pd_parentspecialborderGrid_img {
    height: fit-content;
    display: flex;
    justify-content: space-evenly;
}

.pd_img_slider .css-e1mnrr, .pd_img_slider .css-zbwuqm, .pd_reviewimage {
    display: flex;
    align-items: center;
}

.pd_parentspecialborderGrid, .pd_heading_grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pd_speicalborderGrid {
    padding: 17px 0px 17px 17px;
    border: 1px solid rgba(15, 15, 15, 0.05);
    box-shadow: 0px 20px 94px rgba(16, 24, 40, 0.01), 0px 8px 30px rgba(16, 24, 40, 0.01);
    border-radius: 12px;
    margin-top: 10px !important;
}

.pb_reviewUser_img {
    width: 40px;
    height: 40px;
    border: 1px solid rgba(40, 45, 38, 0.05);
    border-radius: 10px;
}

.pd_review_grid {
    padding: 20px 0;
}

.pd_review_text_heading {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #282D26;
    margin-bottom: 0;
}

.pd_review_title {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0F0F0F;
    margin: 0 0 5px 0;
}

.pd_category_text {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0;

    color: #0957dd;

}

.pd_prod_prop_value_p {
    margin: 0 0 15px 0;
}

.pd_prod_prop {
    color: #0F0F0F;
    opacity: 0.5;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 34px;
}

.pd_prop_value {
    color: #0F0F0F;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 34px;
}

::-webkit-scrollbar {
    display: none;
}